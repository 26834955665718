import React, { Fragment, useState, useEffect } from 'react';
import Icon from '../../atoms/Icon';
import { applySort } from './actions';
import { connect } from 'react-redux';

const availableOptions = [
  {
    field: 'title',
    displayasc: 'Kursnamn (a-ö)',
    displaydesc: 'Kursnamn (ö-a)'
  },
  {
    field: 'courseCode',
    displayasc: 'Kurskod (a-ö)',
    displaydesc: 'Kurskod (ö-a)'
  },
  {
    field: 'courseStart',
    displayasc: 'Närmsta kursstart',
    displaydesc: 'Sista kursstart'
  },
  {
    field: 'relevance',
    display: 'Relevans'
  }
];

const Sorter = ({ sort, sorting: { sortby, direction } }) => {
  const option = availableOptions.find(x => x.field === sortby);
  const [opened, setOpened] = useState(false);
  const firstCharToLower = (str) => str.charAt(0).toLowerCase() + str.slice(1)
  const joined = `${sortby}${direction}`;
  const label =
    option && (option[`display${direction}`] || option['display'])
      ? firstCharToLower(option[`display${direction}`] || option['display'])
      : null;

  const handleOnChange = (props) => {
    sort(props)
    setOpened(false);
  }

  useEffect(() => {
    const onWindowClick = () => {
      setOpened(false);
    };

    window.addEventListener('click', onWindowClick, false);
    return () => window.removeEventListener('click', onWindowClick, false);
  }, []);

  return (
    <div className='sorter'>
      <div className='sorter__wrapper'>
        <div
          onClick={e => {
            e.stopPropagation();
            setOpened(!opened)
          }}
        >
          <span>
            Sortera{label && ' efter '}
            <span className='sorter__selected-sorting'>{label}</span>
          </span>
          <Icon type={opened ? 'chevron-up' : 'chevron-down'} />
        </div>

        {opened && (
          <ul className='sorter__items' onClick={e => e.stopPropagation()}>
            {availableOptions.slice(0, -1).map(x => (
              <Fragment key={x.field}>
                <li className='radiobutton'>
                  <input
                    className='radiobutton__input'
                    onChange={() => handleOnChange({ sortby: x.field, direction: 'asc' })}
                    checked={option && joined == `${x.field}asc`}
                    type='radio'
                    name='sortfield'
                  />
                  <label className='radiobutton__label'>{x.displayasc}</label>
                </li>
                <li className='radiobutton'>
                  <input
                    className='radiobutton__input'
                    onChange={() => handleOnChange({ sortby: x.field, direction: 'desc' })}
                    checked={option && joined == `${x.field}desc`}
                    type='radio'
                    name='sortfield'
                  />
                  <label className='radiobutton__label'>{x.displaydesc}</label>
                </li>
              </Fragment>
            ))}
            <li className='radiobutton'>
              <input
                className='radiobutton__input'
                onChange={() => handleOnChange({ sortby: availableOptions[3].field, direction: null })}
                checked={sortby == availableOptions[3].field}
                type='radio'
                name='sortfield'
              />
              <label className='radiobutton__label'>{availableOptions[3].display}</label>
            </li>
          </ul>
        )}
      </div>
      <div className='separator-wrapper'>
        <div className='separator separator--primary'></div>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  sort: sortProperties => dispatch(applySort(sortProperties))
});

const mapStateToProps = state => {
  return {
    sorting: state.sorting
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Sorter);
