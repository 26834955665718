class UrlService {
  loadParametersFromUrl = (filtersSelectedOnLoad) => {
    const search = {},
      filters = {},
      sorting = {},
      pagination = {};

    const currentUrlParams = new URLSearchParams(decodeURIComponent(window.location.search));

    //search
    const phrase = currentUrlParams.get('phrase');
    const searchInName = currentUrlParams.get('searchinname');
    const includeELearning = currentUrlParams.get('includeeLearning');
    const onlyELearning = currentUrlParams.get('onlyELearning');
    if (phrase) {
      search.phrase = phrase;
    }
    if (searchInName) {
      search.searchInName = Boolean(searchInName);
    }
    if (includeELearning) {
      search.includeELearning = Boolean(includeELearning);
    }

    if (onlyELearning) {
      search.onlyELearning = Boolean(onlyELearning);
    }

    //active filters
    let cities = currentUrlParams.get('cities');
    if (cities) {
      cities = cities.split(',');
      if (cities.length > 0) {
        filters.cities = cities;
      }
    }
    let dates = currentUrlParams.get('dates');
    if (dates) {
      dates = dates.split(',').map(x => {
        const splitted = String(x).split('/');
        return {
          month: +splitted[0],
          year: +splitted[1]
        };
      });
      if (dates.length > 0) {
        filters.dates = dates;
      }
    }
    const group = currentUrlParams.get('group');
    if (group) {
      filters.tagGroup = group;
    }
    const startGuarantee = Boolean(currentUrlParams.get('startguarantee'));
    if (startGuarantee) {
      filters.startGuarantee = startGuarantee;
    }
    const masterCourse = Boolean(currentUrlParams.get('mastercourse'));
    if (masterCourse) {
      filters.masterCourse = masterCourse;
    }
    const vendor = Boolean(currentUrlParams.get('vendor'));
    if (vendor) {
      filters.vendor = vendor;
    }
    const voucher = Boolean(currentUrlParams.get('voucher'));
    if (voucher) {
      filters.voucher = voucher;
    }
    let mainTags = currentUrlParams.get('maintags');
    if (mainTags || filtersSelectedOnLoad) {
      if (mainTags) {
        mainTags = mainTags.split(',');
      }

      if (filtersSelectedOnLoad.length > 0) {
        let preselectedTags = filtersSelectedOnLoad.map((tag) => tag.name);
        
        if (mainTags && mainTags.length > 0) {
          mainTags = mainTags.concat(preselectedTags);
          mainTags = Array.from(new Set(mainTags));
        } else {
          mainTags = preselectedTags;
        }
      }

      if (mainTags && mainTags.length > 0) {
        filters.mainTags = mainTags;
      }
    }
    let tags = currentUrlParams.get('tags');
    if (tags) {
      tags = tags.split(',');
      if (tags.length > 0) {
        filters.activeTags = tags;
      }
    }

    //sorting
    const sortby = currentUrlParams.get('sortby');
    if (sortby) {
      sorting.sortby = sortby;
    }
    const sortDirection = currentUrlParams.get('sortdirection');
    if (sortDirection) {
      sorting.direction = sortDirection;
    }
    if (sorting.direction || sorting.sortby) {
      sorting.fromUrl = true;
    }

    //pagination
    const page = currentUrlParams.get('page');
    if (page) {
      pagination.currentPage = +page <= 0 ? 1 : +page;
    }

    return {
      search,
      filters,
      sorting,
      pagination
    };
  };

  updateUrlWithParameters = (search, filters, sorting, pagination) => {
    const currentUrlParams = new URLSearchParams(window.location.search);
    const set = (name, valueLambda, set) => {
      if (set) {
        currentUrlParams.set(name, valueLambda());
      } else {
        currentUrlParams.delete(name);
      }
    };

    //pagination
    currentUrlParams.set('page', pagination.currentPage);
    this.update(currentUrlParams.toString());

    //sorting
    set('sortby', () => sorting.sortby, Boolean(sorting.sortby));
    set('sortdirection', () => sorting.direction, Boolean(sorting.direction));

    //search
    set('phrase', () => search.phrase, Boolean(search.phrase));
    set('searchinname', () => search.searchInName, Boolean(search.searchInName));
    set('includeeLearning', () => search.includeELearning, Boolean(search.includeELearning));
    set('onlyELearning', () => search.onlyELearning, Boolean(search.onlyELearning));

    //filters
    set('maintags', () => filters.mainTags.join(','), filters.mainTags.length > 0);
    set('tags', () => filters.activeTags.join(','), filters.activeTags.length > 0);
    set('group', () => filters.tagGroup, Boolean(filters.tagGroup));
    set('cities', () => filters.cities.join(','), filters.cities.length > 0);
    set('dates', () => filters.dates.map(date => `${date.month}/${date.year}`).join(','), filters.dates.length > 0);
    set('startguarantee', () => filters.startGuarantee, Boolean(filters.startGuarantee));
    set('mastercourse', () => filters.masterCourse, Boolean(filters.masterCourse));
    set('vendor', () => filters.vendor, Boolean(filters.vendor));
    set('voucher', () => filters.voucher, Boolean(filters.voucher));
    this.update(currentUrlParams.toString());
  };

  update = query => {
    window.history.pushState({}, '', window.location.pathname + (query.length > 0 ? `?${query}` : ''));
  };
}

const urlService = new UrlService();

export default urlService;
