import React from 'react';
import vars from '../../vars';
import SVG from 'react-inlinesvg';

const Icon = ({ size, fromUrl, url, type, color, rotate, onClick }) => {
  if(fromUrl) {
    let className = 'icon';

    if (color) {
      className += ` icon--${color}`;
    }
    if (size) {
      className += ` icon--${size}`;
    }
    if (rotate) {
      className += ` icon--rotate`;
    }

    const imageUrl = `${vars.BASE_URL}${url}`;
    return <SVG src={imageUrl} onClick={onClick} className={className} />;
  }

  let className = `ic-${type}`;
  if(color) {
    className += ` ${color}`;
  }

  if (rotate) {
    className += ` rotate`;
  }

  return <i className={className} onClick={onClick}></i>;
};

export default Icon;
