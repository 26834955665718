import React, { useContext } from 'react';
import LocalizationContext from '../../contexts/LocalizationContext';
import { SnackBarContext } from '../../contexts/SnackbarContext';
import withFavorites from '../../hoc/withFavorites';


const FavoriteStar = ({ sessionId, favorites, addToFavorites, removeFromFavorites, favoritesPageLink, decorateClassName, ...props }) => {
  const isFavorite = favorites.includes(sessionId);
  let translations = useContext(LocalizationContext).translations || props.translations || {};
  const { showAlert } = useContext(SnackBarContext) || {};


  const prepareAlert = () => {
    const addedToFavorites = String(translations.addedToFavorites);
    let split = addedToFavorites.trim().split(' ');
    const lastWord = split[split.length - 1];
    split = split.filter(x => x != lastWord);
    return <div>{split.join(' ') + ' '}<a href={favoritesPageLink}>{lastWord}</a></div>
  }

  const onClick = () => {
    if(isFavorite) {
      removeFromFavorites(sessionId);
      return;
    }

    addToFavorites(sessionId);
    showAlert && showAlert(prepareAlert());
  }

  return (
    <i
      title={isFavorite ? translations.removeFromFavorites : translations.addToFavorites}
      className={`favorite-icon${isFavorite ? ' favorite-icon--added' : ''} ${decorateClassName}`}
      onClick={onClick}
    ></i>
  );
};

export default withFavorites(FavoriteStar);
