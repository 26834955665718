import * as actions from '../actions';
import { initialState } from '../store/initialState';
import urlService from '../../../../services/urlService';

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.INITIALIZE_SUCCESS:
      return {
        ...state,
        isInitialized: true,
        search: { ...state.search, ...action.payload.search },
        filters: { ...state.filters, ...action.payload.filters },
        sorting: { ...state.sorting, ...action.payload.sorting },
        pagination: { ...state.pagination, ...action.payload.pagination }
      };
    case actions.APPLY_SEARCH_PARAMETERS:
      return {
        ...state,
        search: action.payload
      };
    case actions.COURSES_FETCH_REQUEST:
      return { ...state, isFetching: true };
    case actions.COURSES_FETCH_SUCCESS:
      return { ...state, courses: action.payload.courses, isFetching: false };
    case actions.NEXT_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          currentPage: state.pagination.currentPage + 1
        }
      };
    case actions.PREVIOUS_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          currentPage: state.pagination.currentPage > 1 ? state.pagination.currentPage - 1 : 1
        }
      };
    case actions.APPLY_SORT:
      return {
        ...state,
        sorting: action.payload
      };
    case actions.SORT_COURSES_SUCCESS:
      return {
        ...state,
        filteredCourses: action.payload.sortedCourses,
        sorting: action.payload.sorting
      };
    case actions.CLEAR_FILTERS:
      return {
        ...state,
        filters: action.payload
      };
    case actions.APPLY_FILTERS:
      return {
        ...state,
        filters: action.payload
      };
    case actions.FILTER_COURSES_SUCCESS:
      return {
        ...state,
        filteredCourses: action.payload.filteredCourses,
        filters: { ...state.filters, ...action.payload.filters },
        pagination: { ...state.pagination, ...action.payload.pagination }
      };
    default:
      return state;
  }
};

export default rootReducer;
