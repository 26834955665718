import React from 'react';
import ReactDOM from 'react-dom';
import CourseSearch from './components/organisms/CourseSearch';
import Agenda from './components/organisms/Agenda';
import MyFavorites from './components/organisms/MyFavorites';
import Counter from './components/organisms/Counter';
import FavoriteStar from './components/atoms/FavoriteStar';

Object.entries({
    //add react components that will be exposed to user here
    CourseSearch,
    Counter,
    Agenda,
    MyFavorites,
    FavoriteStar
}).forEach(([key,value]) => window[key] = value);

window.React = React;
window.ReactDOM = ReactDOM;