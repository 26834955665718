import moment from "moment";
import dateService from "./dateService";

class SessionsService {
  FAVORITES_LOCAL_STORAGE_KEY = "DEVSUM_FAVORITES";

  getFilters = (sessions = []) =>
    sessions.reduce(
      (filters, session) => {
        const { dates, tags, locations } = filters;
        const date = dateService.parseDate(session.from);

        if (
          !dates.find(
            (x) =>
              x.year === date.year &&
              x.month === date.month &&
              x.day === date.day
          )
        ) {
          dates.push(date);
        }
        session.tags &&
          session.tags.forEach((tag) => {
            if (!tags.find((x) => x === tag.name)) {
              tags.push(tag.name);
            }
          });

        if (!locations.includes(session.location) && session.location) {
          locations.push(session.location);
        }
        return filters;
      },
      {
        dates: [],
        tags: [],
        locations: [],
      }
    );

    filter = (sessions = [], filters) => {
      
    return sessions.filter((session) => {
      let result = true;
        if (filters.date) {
        const date = dateService.parseDate(session.from);
        result =
          filters.date.year === date.year &&
          filters.date.month === date.month &&
          filters.date.day === date.day;
      }
      if (result && filters.tags && filters.tags.length) {
        result = Boolean(
          session.tags.find((x) => filters.tags.includes(x.name))
        );
      }
      if (result && filters.locations && filters.locations.length) {
        result = filters.locations.includes(session.location);
      }
      return result;
    });
  };

  sort = (sessions = []) =>
    sessions.sort((a, b) => {
      const fromA = dateService.parseDate(a.from);
      const fromB = dateService.parseDate(b.from);
      const locationA = a.location;
      const locationB = b.location;
      const sessionA = fromA.hour + fromA.minute * 0.01;
      const sessionB = fromB.hour + fromB.minute * 0.01;
      if (sessionA > sessionB) return 1;
      else if (sessionA < sessionB) return -1;
      else {
        const toA = dateService.parseDate(a.to);
        const toB = dateService.parseDate(b.to);
        const session2A = toA.hour + toA.minute * 0.01;
        const session2B = toB.hour + toB.minute * 0.01;
        if (session2A > session2B) return 1;
        else if (session2A < session2B) return -1;
      }
      // letter                   digit
      if (!/\d/.test(locationA) && /\d/.test(locationB)) {
        return -1;
        // digit                    letter
      } else if (/\d/.test(locationA) && !/\d/.test(locationB)) {
        return 1;
      }

      if (locationA === null) {
        return -1;
      }
      if (locationB === null) {
        return 1;
      }

      return locationA.localeCompare(locationB);
    });

  group = (sessions = []) =>
    sessions.reduce((groups, session) => {
      const from = dateService.parseDate(session.from);
      const fromDate = new Date(from.year, from.month, from.day, from.hour, from.minute);
      const to = dateService.parseDate(session.to);
      const toDate = new Date(to.year, to.month, to.day, to.hour, to.minute);
      const dateKey = fromDate.valueOf() + "-" + toDate.valueOf();
      const groupKey = dateKey;

      if (groups[groupKey]) {
        groups[groupKey].push(session);
      } else {
        groups[groupKey] = [session];
        }
      return groups;
    }, {});

  getFavorites = () => {
    const item = localStorage.getItem(this.FAVORITES_LOCAL_STORAGE_KEY);
    return item ? JSON.parse(item) : [];
  };

  addToFavorites = (id) => {
    const favorites = this.getFavorites();
    if (!favorites.includes(id)) {
      favorites.push(id);
    }
    localStorage.setItem(
      this.FAVORITES_LOCAL_STORAGE_KEY,
      JSON.stringify(favorites)
    );
    return favorites;
  };

  removeFromFavorites = (id) => {
    const favorites = this.getFavorites().filter((x) => x != id);
    localStorage.setItem(
      this.FAVORITES_LOCAL_STORAGE_KEY,
      JSON.stringify(favorites)
    );
    return favorites;
  };
}

export default new SessionsService();
