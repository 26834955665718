import React, { useEffect } from "react";
import Button from "./Button";
import vars from "../../vars";

const Image = ({ url }) => (
  <div className="course-card__image-container">
    {url && (
      <img className="course-card__image" src={`${vars.BASE_URL}${url}`} />
    )}
  </div>
);

const CourseCard = ({ course, onClick }) => {
  const {
    courseCode,
    title,
    courseLength,
    price,
    currency,
    imageUrl,
    absoluteUrl,
    isELearning
  } = course;

  useEffect(() => {
    const title = document.getElementById(courseCode);
    window.shrinkCourseCardTitle(title);
  });

  const innerOnClick = () => {
    window.dataLayer && window.dataLayer.push({
      'productName': title,
      'event': 'coursesAndCertificationsReadMoreAndBook'
    });
    window.desktopLink(absoluteUrl);
  }

  return (
    <div
      className="course-card"
      onClick={innerOnClick}
    >
      <Image url={imageUrl} />
      <div className="course-card__info">
        <h4 className="course-card__info__title" id={courseCode}>
          <a href={absoluteUrl}>{title}</a>
        </h4>
        <div class="course-card__info__separator"></div>
        <div className="course-card__info__grid">
          <div className="course-card__info__grid-cell course-card__info__grid-cell--length">
            {isELearning ? <i class="ic-laptop"></i> : "Längd "}
            <span class="course-card__info__grid-cell course-card__info__grid-cell--bold">
            {isELearning ? "E-Learning" : `${courseLength == null ? "" : courseLength}`}
            </span>
          </div>
          <div className="course-card__info__grid-cell course-card__info__grid-cell--code">
            Kurskod{" "}
            <span className="course-card__info__grid-cell course-card__info__grid-cell--bold">
              {courseCode}
            </span>
          </div>

          <div className="course-card__info__grid-cell course-card__info__grid-cell--price">
            Pris{" "}
            <span className="course-card__info__grid-cell course-card__info__grid-cell--bold">{`${price} ${currency == null ? "" : currency}`}</span>
          </div>
        </div>
        <div className="course-card__book-button">
          <Button type="secondary" onClick={onClick}>
            Läs mer och boka
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CourseCard;
