import coursesService from '../../../../services/coursesService';
import urlService from '../../../../services/urlService';

export const APPLY_SEARCH_PARAMETERS = 'APPLY_SEARCH_PARAMETERS';
export const COURSES_FETCH_REQUEST = 'FETCH_REQUEST';
export const COURSES_FETCH_SUCCESS = 'FETCH_SUCCESS';

export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const APPLY_FILTERS = 'APPLY_FILTERS';
export const FILTER_COURSES_SUCCESS = 'FILTER_COURSES_SUCCESS';

export const APPLY_SORT = 'APPLY_SORT';
export const SORT_COURSES_SUCCESS = 'SORT_COURSES_SUCCESS';

export const NEXT_PAGE = 'NEXT_PAGE';
export const PREVIOUS_PAGE = 'PREVIOUS_PAGE';

export const INITIALIZE = 'INITIALIZE';
export const INITIALIZE_SUCCESS = 'INITIALIZE_SUCCESS';

const updateUrlWithParams = () => async (_, getState) => {
  const { search, filters, pagination, sorting } = await getState();
  urlService.updateUrlWithParameters(search, filters, sorting, pagination);
};

export const nextPage = () => dispatch => {
  dispatch({
    type: NEXT_PAGE
  });
  dispatch(updateUrlWithParams());
};

export const previousPage = () => dispatch => {
  dispatch({
    type: PREVIOUS_PAGE
  });
  dispatch(updateUrlWithParams());
};

export const initialize = () => async (dispatch, getState) => {
  const { initialFilters } = await getState();
  const filtersSelectedOnLoad = initialFilters.mainTags.filter((tag) => {
    return tag.selected === true;
  });

  dispatch({
    type: INITIALIZE_SUCCESS,
    payload: urlService.loadParametersFromUrl(filtersSelectedOnLoad)
  });

  dispatch(fetchCourses(false));
};

export const sortCourses = () => async (dispatch, getState) => {
  const { filteredCourses, sorting } = await getState();
  dispatch({
    type: SORT_COURSES_SUCCESS,
    payload: coursesService.sortCourses(filteredCourses, sorting)
  });
  dispatch(updateUrlWithParams());
};

export const applySearchParameters = (params, fetchAfter = false) => async (dispatch, getState) => {
  let { search } = await getState();
  search = {
    ...search,
    ...params
  };

  dispatch({
    type: APPLY_SEARCH_PARAMETERS,
    payload: search
  });

  if (fetchAfter) {
    dispatch(fetchCourses());
  }
};

export const clearFilters = () => async (dispatch, getState) => {
  const { initialFilters } = await getState();

  const filtersSelectedOnLoad = initialFilters.mainTags.filter((tag) => tag.selected === true)
                                                      .map((tag) => tag.name);

    dispatch({
      type: CLEAR_FILTERS,
      payload: {
        mainTags: filtersSelectedOnLoad,
        tags: [],
        activeTags: [],
        availableCities: [],
        availableDates: [],
        coursesCountForTags: {},
        cities: [],
        dates: [],
        startGuarantee: false,
        masterCourse: false,
        vendor: false,
        voucher: false
      }
    });
  dispatch(filterCourses());
};

export const applyFilters = (filtersToChange, buildFilters = true) => async (dispatch, getState) => {
  let { filters, search } = await getState();

  filters = {
    ...filters,
    ...filtersToChange
  };

  dispatch({
    type: APPLY_FILTERS,
    payload: filters
  });

  dispatch(applySearchParameters({ phrase: search.phrase }, true));
};

export const applySort = sortProperties => async (dispatch, getState) => {
  let { sorting } = await getState();
  sorting = {
    ...sorting,
    ...sortProperties
  };

  dispatch({
    type: APPLY_SORT,
    payload: sorting
  });

  if (!sorting.highlightedFirst && sorting.sortby === 'relevance') {
    dispatch(filterCourses(false));
  }

  dispatch(sortCourses());
};

export const filterCourses = (clearPagination = true, buildFilters = true) => async (dispatch, getState) => {
  const { courses, filters, search, pagination, initialFilters } = await getState();
  dispatch({
    type: FILTER_COURSES_SUCCESS,
    payload: {
      ...coursesService.filterCourses(courses, filters, search, initialFilters, buildFilters),
      pagination: { currentPage: clearPagination ? 1 : pagination.currentPage }
    }
  });

  dispatch(sortCourses());
};

export const fetchCourses = (clearPagination = true) => async (dispatch, getState) => {
  const { search } = await getState();

  dispatch({
    type: COURSES_FETCH_REQUEST
  });

  let courses = await coursesService.getCoursesForProperties(search);

  dispatch({
    type: COURSES_FETCH_SUCCESS,
    payload: { courses }
  });

  dispatch(filterCourses(clearPagination));
};
