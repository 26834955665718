import React from 'react';
import CourseSearch from './CourseSearch';
import buildStore from './store';
import { Provider } from 'react-redux';
import MomentLanguageContext from '../../../contexts/MomentLanguageContext';

const Root = ({
  mainTags = [],
  eLearningToolTipText = null,
  cities = [],
  followingMonthsNumber = 8,
  filterTypeTooltips = [],
  preFiltered = false
}) => {
  const LanguageProvider = MomentLanguageContext.Provider;
  const store = buildStore({
    initialFilters: {
      mainTags,
      cities,
      followingMonthsNumber,
      preFiltered
    },
    eLearningToolTipText,
    filterTypeTooltips
  });
  return (
    <Provider store={store}>
      <LanguageProvider>
          <CourseSearch />
      </LanguageProvider>
    </Provider>
  );
};

export default Root;