import React from 'react';
import sessionsService from '../../services/sessionsService';
import PropTypes from 'prop-types';
import Session from './Session';
import dateService from '../../services/dateService';

const GroupedSessionList = ({
    sessions,
    favorites,
    showOnlyFavorites,
    children,
    favoritesPageLink
}) => {
    const filteredSessions = showOnlyFavorites ? sessions.filter(x => favorites.includes(x.id)) : sessions;
    const sortedSessions = sessionsService.sort(filteredSessions);
    const groupedSessions = sessionsService.group(sortedSessions);

    const mapSession = (session, i) =>
        Boolean(session) ? (
            <React.Fragment key={session.id}>
                <Session key={session.id} session={session} favoritesPageLink={favoritesPageLink} />
            </React.Fragment>
        ) : (
            <br key={i} />
        );

    const createGroupedSessionList = (group) => {
        if (group) {
            const [{from, to}] = group;
            return (
            <div className='grouped-session-list'>
                { from && to &&
                (
                <>
                <div className='grouped-session-list__timebox'>
                    <div className='grouped-session-list__timebox__time'>
                        <div>
                            {dateService.formatTime(dateService.parseDate(from).hour, dateService.parseDate(from).minute)}
                        </div> 
                        <div>-</div>
                        <div>
                            {dateService.formatTime(dateService.parseDate(to).hour, dateService.parseDate(to).minute)}
                        </div> 
                    </div>
                </div>

                <div className='sessions'>
                    {children && typeof children === 'function' ? children(group, mapSession) : group.map(mapSession)}
                </div>
                </>
                )}
            </div>)
        }
    };
    return (
        <> 
            {Object.keys(groupedSessions).length > 0  && (
                Object.values(groupedSessions).map((group) => createGroupedSessionList(group))
            )}
        </>
    );
};

GroupedSessionList.propTypes = {
    sessions: PropTypes.arrayOf(Session.propTypes.session)
};

export default GroupedSessionList;
