import React, { Fragment, useState } from 'react';
import Checkbox from './Checkbox';
import Separator from '../atoms/Separator';

const Dropdown = (props) => {
  const {
    children,
    options,
    type,
    disabled,
    onClick,
    onOptionChange,
    closeOnChange
  } = props;
  let className = 'dropdown';
  let iconClass = '';
  const isDark = type === 'dark';

  const isControlled = props.opened !== undefined;
  const [internalOpened,setInternalOpened] = useState(false);
  const getOpened = () => {
    return isControlled ? props.opened : internalOpened;
  }
  const onToggle = (e) => {
    isControlled ? onClick(e) : setInternalOpened(!internalOpened);
  }

  if (isDark) {
    className += ' dropdown--dark';
  }

  if (getOpened()) {
    className += ' dropdown--opened';
    iconClass = 'ic-chevron-up';
  } else {
    iconClass = 'ic-chevron-down';
  }
  if(disabled) {
    className += ' dropdown--hidden';
  }

  return (
    <div className={className} onClick={onToggle}>
      <span className="dropdown__title">{children}</span>
      <i className={iconClass}></i>
      <div onClick={e => e.stopPropagation()} className="dropdown__content">
        <Separator type={isDark ? 'light' : 'dark'} dotted />
        {options.map(x => (
          <Fragment key={x.option}>
            <li>
              <Checkbox
                type={isDark ? 'white' : 'dark'}
                label={x.option}
                additionalLabelText={x.additionalText}
                checked={x.checked}
                onChange={e => {
                  onOptionChange(x.option, e.target.checked);
                  closeOnChange && onToggle(e);
                }}
              />
            </li>
            <Separator type={isDark ? 'light' : 'dark'} dotted />
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default Dropdown;
