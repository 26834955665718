import vars from '../vars';
import axios from 'axios';
import moment from 'moment';

class CoursesService {
  _endpoint = `${vars.API_URL}/courses`;

  getCoursesForProperties = async searchProperties => {
    try {
      return (
        await axios.get(this._endpoint, {
          params: {
            ...searchProperties
          }
        })
      ).data;
    } catch (err) {
      throw err;
    }
  };

  filterCourses = (courses, filters, search, initialFilters, buildFilters = true) => {
    const anyFilter =
      filters.mainTags.length > 0 ||
      filters.activeTags.length > 0 ||
      filters.cities.length > 0 ||
      filters.dates.length > 0 ||
      filters.startGuarantee ||
      filters.masterCourse ||
      filters.vendor ||
      filters.voucher ||
      filters.eLearning;

    if (!anyFilter) {
      const filteredCourses = courses && courses.length > 0 ? [...courses] : [];
      return {
        filteredCourses,
        filters: {
          ...this.buildFiltersBasedOnCourses(filteredCourses, courses),
          anyFilter
        }
      };
    }
    const now = moment();
    const tags = [];
    let activeTags = null;
    const filteredCourses = courses.filter(course => {
      let result = true;
      if (filters.mainTags && filters.mainTags.length > 0 && !filters.tagGroup) {
        result = course.tags.some(t => filters.mainTags.includes(t.name));
        result &&
          course.tags
            .filter(t => !initialFilters.mainTags.find(x => x.name === t.name))
            .forEach(t => !tags.includes(t.name) && tags.push(t.name));
        if (result && filters.activeTags.length > 0) {
          result = course.tags.some(t => filters.activeTags.includes(t.name));
        }
      } else if (result && filters.tagGroup && filters.activeTags.length > 0) {
        result = course.tags.some(t => filters.activeTags.includes(t.name));
      } else if (!filters.mainTags || filters.mainTags.length <= 0) {
        activeTags = [];
      }
      if (result && filters.cities && filters.cities.length > 0) {
        result = Boolean(course.courseDateSummaries.find(x => filters.cities.find(c => c === x.city)));
      }
      if (result && filters.dates && filters.dates.length > 0) {
        result = Boolean(
          course.courseDateSummaries.find(x =>
              x.dates.find(d => {
                const date = moment(d.date);

                if (filters.cities && filters.cities.length > 0 && !Boolean(filters.cities.find(c => c === x.city)))
                  return false;

                if (date < now && !date.isSame(now, 'day')) return false;
                return filters.dates.find(x => x.month === date.month() + 1 && x.year === date.year());
              })
          )
        );
      }
      if (result && filters.startGuarantee) {
        result = Boolean(course.courseDateSummaries.find(x => x.dates.find(d => d.hasStartGuarantee)));
      }
      if (result && filters.masterCourse) {
        result = Boolean(course.isMasterCourse);
      }
      const str = String(course.voucher).toLowerCase();
      if (result && filters.vendor && filters.voucher) {
        result = str === 'both';
      } else if (result && filters.vendor) {
        result = str === 'vendor' || str === 'both';
      } else if (result && filters.voucher) {
        result = str === 'cornerstone' || str === 'both';
      }
      return result;
    });

    let builtFilters = {};
    if (buildFilters) {
      builtFilters = this.buildFiltersBasedOnCourses(filteredCourses, courses);
    }

    const toReturn = {
      filteredCourses,
      filters: {
        ...builtFilters,
        tags,
        ...(activeTags ? { activeTags } : {}),
        anyFilter: true
      }
    };
    return toReturn;
  };

  getSortFunction = (key, { direction, property }) => {
    const now = moment();
    return {
      string: (a, b) => {
        if (direction === 'asc')
          return String(a[property]).localeCompare(String(b[property]), 'sv', {
            sensitivity: 'base'
          });
        return String(b[property]).localeCompare(String(a[property]), 'sv', { sensitivity: 'base' });
      },
      coursedates: (a, b) => {
        const diffDatesFromA = [];
        const diffDatesFromB = [];
        a.courseDateSummaries.forEach(x =>
          x.dates.filter(d => d.date).forEach(d => diffDatesFromA.push(now.diff(moment(d.date))))
        );
        b.courseDateSummaries.forEach(x =>
          x.dates.filter(d => d.date).forEach(d => diffDatesFromB.push(now.diff(moment(d.date))))
        );
        let smallestA = Math.min(...diffDatesFromA.filter(x => x < 0).map(diff => diff * -1));
        let smallestB = Math.min(...diffDatesFromB.filter(x => x < 0).map(diff => diff * -1));
        let result = 0;
        if (isNaN(smallestA) && isNaN(smallestB)) {
          result = 0;
        } else if (isNaN(smallestA) && !isNaN(smallestB)) {
          result = 1;
        } else if (!isNaN(smallestA) && isNaN(smallestB)) {
          result = -1;
        } else {
          result = smallestA - smallestB;
        }
        return direction === 'asc' ? result : result * -1;
      }
    }[String(key).toLowerCase()];
  };

  sortByHighlightedAndRelevance = courses => {
    const highlighted = courses.filter(x => x.isHighlighted);
    const unHighlighted = courses.filter(x => !x.isHighlighted);
    return [...highlighted, ...unHighlighted];
  };

  sortByOther = (courses, sorting) => {
    let sortFunction;
    switch (sorting.sortby) {
      case 'title':
      case 'courseCode':
        sortFunction = this.getSortFunction('string', { direction: sorting.direction, property: sorting.sortby });
        break;
      case 'courseStart':
        sortFunction = this.getSortFunction('coursedates', { direction: sorting.direction });
        break;
      default:
        sortFunction = (a, b) => 0;
    }
    return [...courses.sort(sortFunction)];
  };

  sortCourses = (filteredCourses, sorting) => {
    let sortedCourses = [];
    if (!sorting.fromUrl && sorting.highlightedFirst) {
      sortedCourses = this.sortByHighlightedAndRelevance(filteredCourses);
    } else if (!sorting.sortby || sorting.sortby === 'relevance') {
      sortedCourses = [...filteredCourses];
    } else {
      sortedCourses = this.sortByOther(filteredCourses, sorting);
    }

    return {
      sortedCourses,
      sorting: {
        ...sorting,
        highlightedFirst: false
      }
    };
  };

  buildFiltersBasedOnCourses = (fiteredcourses, courses) => {
    const availableCities = [];
    const availableDates = [];
    const coursesCountForTags = {};
    courses.forEach(course => {
      course.tags.forEach(t => {
        if (coursesCountForTags[t.name]) {
          coursesCountForTags[t.name]++;
        } else {
          coursesCountForTags[t.name] = 1;
        }
      });
    });
    const now = moment();
    fiteredcourses.forEach(course => {
      course.courseDateSummaries.forEach(x => {
        x.dates.forEach(z => {
          const date = moment(z.date);
          if (date < now && !date.isSame(now, 'day')) {
            return;
          }
          if (!availableDates.find(d => d.month === date.month() + 1 && d.year === date.year())) {
            availableDates.push({
              month: date.month() + 1,
              year: date.year(),
              city: x
            });
          }
        });
        if (!availableCities.find(c => c === x.city)) {
          availableCities.push(x.city);
        }
      });
    });

    return {
      availableCities,
      availableDates,
      coursesCountForTags
    };
  };
}

export default new CoursesService();
