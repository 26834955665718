import React, { Component, useEffect, useState } from 'react';
import Separator from '../../../atoms/Separator';
import Icon from '../../../atoms/Icon';
import Button from '../../../molecules/Button';
import TagGroupsFilters from './TagGroupsFilters';
import AdvancedFilters from './AdvancedFilters';
import TagLabelsFilters from './TagLabelsFilters';
import { connect } from 'react-redux';
import TagsFilters from './TagsFilters';
import { clearFilters } from '../actions';


const gtmClearButtonOnClick = () => {
  window.dataLayer && window.dataLayer.push({
    'event': 'coursesAndCertificationsClearFilter'
  });
}

const Filters = function({ onRef, onUnRef, clearFilters, filters: { anyFilter } }) {
  const [advancedFiltersOpened, setAdvancedFiltersOpened] = useState(false);
  const toggleAdvancedFilters = () => {
    setAdvancedFiltersOpened(!advancedFiltersOpened);
    const advancesFiltersElementRef = document.getElementById('advanced-filters');
    const classToToggle = 'advanced-filters--hidden';
    window.toggleElementHeight(advancesFiltersElementRef, classToToggle);
  };

  useEffect(() => {
    onRef && onRef(this);
    return () => {
      onUnRef && onUnRef(this);
    };
  }, []);

  return (
    <div className='filters'>
      <div className='filters__background'>
        <div className='filters__wrapper container'>
          <TagsFilters />
          <div className='buttons'>
            <Button onClick={() => {
              gtmClearButtonOnClick();
              clearFilters();
            }} type='secondary' size='small' icon='trash-alt' disabled={!anyFilter}>
              Ta bort val
            </Button>
          </div>
          <span className='blank-grid-cell' />
          <div className='filters-open'>
            <span onClick={() => toggleAdvancedFilters()}>
              Avancerad sök
              <Icon type={advancedFiltersOpened ? 'chevron-up' : 'chevron-down'} />
            </span>
          </div>
        </div>
      </div>

      <Separator type='light' />
      <AdvancedFilters afterInitialized={anyActiveFilter => anyActiveFilter && toggleAdvancedFilters()} />
      <TagLabelsFilters/>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  clearFilters: () => dispatch(clearFilters())
});

const mapStateToProps = state => {
  return {
    initialFilters: state.initialFilters,
    filters: state.filters,
    filterTypeTooltips: state.filterTypeTooltips
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Filters);
