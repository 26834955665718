import React from 'react';
import PropTypes from 'prop-types';
import FavoriteStar from '../atoms/FavoriteStar';

const Session = ({ session, favoritesPageLink}) => {
  return (
    <div className='session'>
      <FavoriteStar sessionId={session.id} decorateClassName='session__favorite-icon' favoritesPageLink={favoritesPageLink}/>
      <div className='wrapper'>
        <h3 className='session__name'>
          <a href={session.url}>{session.name}</a>
        </h3>
        <h4 className='session__location'>
          <i className='ic-compass'></i>
          {session.location}
        </h4>
        <div className='inner-wrapper'>
          {
          session != null && session.speakers != null && session.speakers.length > 0 && (
          <div className='session__speakers'>
            {session.speakers.map(s => (
              <a href={s.url} key={s.name} className='speaker'>
                <span style={{ backgroundImage: `url(${s.imageUrl})` }}></span>
                <h4>{s.name}</h4>
              </a>
            ))}
          </div>
          )}
          <p className='session__description' dangerouslySetInnerHTML={{ __html: session.description }} />
        </div>
      </div>
    </div>
  );
};

Session.propTypes = {
  session: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    location: PropTypes.string,
    from: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string
      })
    ),
    speakers: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        url: PropTypes.string,
        imageUrl: PropTypes.imageUrl
      })
    )
  }).isRequired,
  isFavorite: PropTypes.bool,
  onFavoriteChange: PropTypes.func
};

export default Session;
