import React, { Component } from 'react';
import Checkbox from '../../../molecules/Checkbox';
import ToolTip from './ToolTip';
import { fetchCourses, applySearchParameters } from '../actions';
import { connect } from 'react-redux';

class Search extends Component {
  _inputRef = React.createRef();
  state = {};

  componentDidUpdate() {
    window.fitInputToContent(this._inputRef.current);
    const {
      search: { phrase }
    } = this.props;
    this._inputRef.current.value = phrase; //react hack, avoid jumping cursor to end
  }

  onSubmit = e => {
    if (e) {
      e.preventDefault();
    }
    const { searchForCourses } = this.props;
    searchForCourses();
  };

  onSearchClick = e => e.stopPropagation();

  onInputChange = e => {
    const { applySearchParameters } = this.props;
    applySearchParameters({ phrase: e.target.value });
    window.fitInputToContent(e.target);
  };

  render() {
    const {
      eLearningToolTip,
      search: { phrase, searchInName, includeELearning }
    } = this.props;
    const { applySearchParameters } = this.props;
    const { checkboxesShown } = this.state;
    return (
      <div className={`search${checkboxesShown ? ' search--checkboxes-shown' : ''}`} onClick={this.onSearchClick}>
        <div className='search__wrapper container'>
          <form className='search__wrapper__form' onSubmit={this.onSubmit}>
            <button type='submit' class='button--search' title='Sök'>
              <i class='ic-search'></i>
            </button>
            <input
              id='course-search'
              className='input input--search'
              type='search'
              onChange={this.onInputChange}
              placeholder={'Sök efter utbildning'}
              defaultValue={phrase}
              ref={this._inputRef}
            />
          </form>
          <div className={`search__checkboxes`} id='search-checkboxes'>
            <Checkbox
              checked={searchInName}
              onChange={e => applySearchParameters({ searchInName: e.target.checked }, true)}
              label='Sök endast på kurstitel'
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  applySearchParameters: (params, fetchAfter) => dispatch(applySearchParameters(params, fetchAfter)),
  searchForCourses: () => dispatch(fetchCourses())
});

const mapStateToProps = state => {
  return {
    initialFilters: state.initialFilters,
    filters: state.filters,
    search: state.search,
    eLearningToolTip: state.eLearningToolTipText
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
