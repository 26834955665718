import React, { Component } from 'react';
import sessionsService from '../../../services/sessionsService';
import Dropdown from '../../molecules/Dropdown';
import GroupedSessionList from '../../molecules/GroupedSessionList';
import dateService from '../../../services/dateService';
import withProvidingLocalization from '../../../hoc/withProvidingLocalization';
import withSnackbar from '../../../hoc/withSnackbar';

class Agenda extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => {
    return {
      filters: {
        dates: [],
        tags: [],
        locations: []
      },
      activeFilters: {
        date: null,
        tags: [],
        locations: []
      },
    };
  };

  setQueryValue = (id, value) => {
    var searchParams = new URLSearchParams(window.location.search);
    searchParams.set(id, value);
    window.history.replaceState({}, '', `${window.location.pathname}?${searchParams}`);
  }

  onDateClick = date => {
    this.setState(prevState => {
      this.setQueryValue("date", date.day+"-"+date.month+"-"+date.year);
      return ({
        activeFilters: {
          ...prevState.activeFilters,
          date: date
        }
      });
    });
  };

  onLeftArrowClick(dateFilters, currentDate) {
    var currentIndex = dateFilters.findIndex(obj=> obj.year === currentDate.year && obj.month === currentDate.month && obj.day === currentDate.day);
    if(currentIndex === 0 || currentIndex === -1)
    {
      return;
    }
    var updatedDate = dateFilters[--currentIndex];
    this.onDateClick(updatedDate);
  }

  onRightArrowClick(dateFilters, currentDate) {
    var currentIndex = dateFilters.findIndex(obj=> obj.year === currentDate.year && obj.month === currentDate.month && obj.day === currentDate.day);
    if(currentIndex === (dateFilters.length - 1) || currentIndex === -1)
    {
      return;
    }
    var updatedDate = dateFilters[++currentIndex];
    this.onDateClick(updatedDate);
  }

  componentDidMount = () => {
    var searchParams = new URLSearchParams(window.location.search);
    let locationsQuery = searchParams.get("locations");
    let tagsQuery = searchParams.get("tags");
    let dateQuery = searchParams.get("date");

    const { sessions, localization } = this.props;
    dateService.useLocalization(localization);
    const filters = sessionsService.getFilters(sessions);
    filters.dates = filters.dates.sort((a,b) => (a.year + a.month * 10 + a.day) - (b.year + b.month * 10 + b.day));
    this.setState(prevState => {
      let newActiveFilters = {
        ...prevState.activeFilters,
        date: filters.dates.length > 0 ? filters.dates[0] : prevState.activeFilters.date,
      };

      if(locationsQuery !== null && locationsQuery.length > 0) {
        let filteredLocations = locationsQuery.split(",");
        if(filteredLocations.length > 0) newActiveFilters.locations = filteredLocations;
      }

      if(tagsQuery !== null && tagsQuery.length > 0) {
        let filteredTags = tagsQuery.split(",");
        if(filteredTags.length > 0) newActiveFilters.tags = filteredTags;
      }

      if(dateQuery !== null && dateQuery.length > 0){
        let splittedDate = dateQuery.split("-");
        if(splittedDate.length > 2) {
          newActiveFilters.date = {
            day: Number(splittedDate[0]),
            month: Number(splittedDate[1]),
            year: Number(splittedDate[2])
          }
        }
      }
      
      return ({
        filters,
        activeFilters: newActiveFilters
      });
    });
  };

  onLocationChange = (location, checked) =>
    this.setState(({activeFilters}) => {
      let updatedLocations = [...activeFilters.locations].filter(x => x !== location).concat(checked ? [location] : []);
      this.setQueryValue("locations", updatedLocations.join(","));

      return ({
        activeFilters: {
          ...activeFilters,
          locations: updatedLocations
        }
      });
    });

  onTagChange = (tag, checked) =>
    this.setState(({ activeFilters }) => {
    let updatedTags = [...activeFilters.tags].filter(x => x !== tag).concat(...(checked ? [tag] : []));
    this.setQueryValue("tags", updatedTags.join(","));

    return ({
      activeFilters: {
        ...activeFilters,
        tags: updatedTags
      }
    });
  });

  isCurrentOrNeighbour(dates, currentDate, dateToCompare){
    const currentIndex = dates.findIndex(obj=> obj.year === currentDate.year && obj.month === currentDate.month && obj.day === currentDate.day);
    if (dates[currentIndex] === dateToCompare){
      return true;
    }
    if(currentIndex !== 0 && dates[currentIndex-1] === dateToCompare){
      return true;
    }
    if(currentIndex !== (dates.length - 1) && dates[currentIndex+1] === dateToCompare){
      return true;
    }
    return false;
  }


  render() {
    const { sessions, translations, favoritesPageLink } = this.props;
    const { filters, activeFilters } = this.state;
      const filteredSessions = sessionsService.filter(sessions, activeFilters);
    return (
      <>
        <div className='filters'>
          <div className='filters__date-picker'>
          <i className="ic-chevron-left" onClick={()=> this.onLeftArrowClick(filters.dates, activeFilters.date)}></i>
          <ul className='filters__dates'>
            {filters.dates.map(d => {
              const displayDate = dateService.formatDate(d).toUpperCase();
              const active = displayDate === dateService.formatDate(activeFilters.date).toUpperCase();
              return (
                <>
                  { this.isCurrentOrNeighbour(filters.dates, activeFilters.date, d)  && (<li key={displayDate} className={active ? 'active' : ''} onClick={() => this.onDateClick(d)}>
                    {displayDate}
                  </li>)}
                </>
              );
            })}
          </ul>
          <i className="ic-chevron-right" onClick={()=> this.onRightArrowClick(filters.dates, activeFilters.date) }></i>
          </div>
          <div className='filters__location-tags'>
            <Dropdown
              options={filters.locations.map(x => ({
                option: x,
                checked: activeFilters.locations.includes(x)
              }))}
              onOptionChange={this.onLocationChange}
            >
              {translations['locations']}
            </Dropdown>
            <Dropdown
              options={filters.tags.map(x => ({
                option: x,
                checked: activeFilters.tags.includes(x)
              }))}
              onOptionChange={this.onTagChange}
            >
              {translations['tags']}
            </Dropdown>
          </div>
        </div>
        <GroupedSessionList sessions={filteredSessions} favoritesPageLink={favoritesPageLink}/>
      </>
    );
  }
}

export default withProvidingLocalization(withSnackbar(Agenda));
