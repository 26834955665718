import React from 'react';
import Icon from '../atoms/Icon';

const Checkbox = ({ label, additionalLabelText, onChange, checked, type, icon, defaultChecked, disabled, tooltipName, tooltipContent }) => {
  const onClick = (e) => {
    window.toggleTooltip(String(tooltipName), 'tooltip-text--hidden');
    e.stopPropagation();
    e.preventDefault();
  };

  let className = 'checkbox';
  if (type) {
    className += ` checkbox--${type}`;
  }

  if(disabled) {
    className += ` checkbox--disabled`;
  }

  return (
      <div className={className}>
        <span className="checkbox__label">
          {label}
          {' '}
          {icon && <Icon type={icon} />}
          {additionalLabelText && <span className="checkbox__label--additional">{additionalLabelText}</span>}
          {
            tooltipName && tooltipContent && 
            <span className='tooltip-icon tooltip-icon-container' onClick={onClick}>
              <i className='ic-question-circle' id={`icon-${tooltipName}`}></i>
            </span>
          }
        </span>
        <input
          className="checkbox__input"
          name={label}
          onChange={onChange}
          type="checkbox"
          checked={checked && !disabled}
          defaultChecked={defaultChecked}
        />
        <span className="checkbox__checkmark">
          <i className="ic-times"></i>
        </span>
        {
          tooltipName && tooltipContent &&
          <div className={`tooltip-text tooltip-text--hidden box-${tooltipName}`}>
            <span dangerouslySetInnerHTML={{ __html: tooltipContent }} />
          </div>
        }
      </div>
  );
};

export default Checkbox;
