import moment from 'moment';

class DateService {
    _localization = 'en';

    parseDate = (stringDate) => {
        //from UTC format
        const year = +stringDate.substr(0,4);
        const month = +stringDate.substr(5,2);
        const day = +stringDate.substr(8,2);
        const hour = +stringDate.substr(11,2);
        const minute = +stringDate.substr(14,2);
        return {
            year,
            month,
            day,
            hour,
            minute
        };
      }

      addZeroIfNeeded = (number) => {
          const asString = String(number);
          return asString.length === 1 ? '0' + asString : asString;
      }

      formatDate = (date) => {
          const monthsShort = moment.monthsShort();
          return `${monthsShort[date.month - 1]} ${this.addZeroIfNeeded(date.day)}, ${date.year}`;
      }

      formatTime = (hour, minute) => {
          const theTime = hour >= 12 ? 'PM' : 'AM';
          const correctedHour = hour > 12 ? hour - 12 : hour;
          return `${correctedHour}:${this.addZeroIfNeeded(minute)} ${theTime}`;
      }

      sortDateFunc = (a,b) => (a.year + a.month * 10 + a.day) - (b.year + b.month * 10 + b.day);

      useLocalization = (localization) => {
          if(localization) {
            this._localization = localization;
            moment.locale(localization);
          }
      }
}

export default new DateService();