import React from 'react';

const TagLabel = ({ children, onXClick }) => <span className='tag-label'>
    <span className='tag-label__text'>
        {children}
    </span>
    <span className='tag-label__close-icon' onClick={onXClick}>
        <i className='ic-times'></i>
    </span>
</span>

export default TagLabel;