import React, { useEffect, useState } from 'react';
import Dropdown from '../../../molecules/Dropdown';
import { connect } from 'react-redux';
import { applyFilters } from '../actions';

const gtmOnTopicClick = (filterName) => {
  window.dataLayer && window.dataLayer.push({
    'filterTitle': filterName,
    'event': 'coursesAndCertificationsTopicFilter'
  });
}

const gtmOnSubtopicClick = (filterName) => {
  window.dataLayer && window.dataLayer.push({
    'filterTitle': filterName,
    'event': 'coursesAndCertificationsSubtopicFilter'
  });
}

const TagsFilters = ({ filter, filters, initialFilters: { mainTags, preFiltered } }) => {
  const onMainTagsOptionChange = (name, checked) => {
    let { mainTags } = filters;
    let activeTags = filters.activeTags;
    if (checked) {
      gtmOnTopicClick(name);
      if(filters.tagGroup) {
        activeTags = [];
        mainTags = [];
      }
      mainTags.push(name);
    } else {
      mainTags = mainTags.filter(x => x !== name);
    }
    filter({ mainTags, activeTags });
  };
  const onTagsOptionChange = (name, checked) => {
    let { activeTags } = filters;
    if (checked) {
      gtmOnSubtopicClick(name);
      activeTags.push(name);
    } else {
      activeTags = activeTags.filter(x => x !== name);
    }
    filter({ activeTags });
  };

  const [{ mainTagsOpened, tagsOpened }, setState] = useState({
    mainTagsOpened: false,
    tagsOpened: false
  });
  useEffect(() => {
    const onWindowClick = () => {
      setState({
        mainTagsOpened: false,
        tagsOpened: false
      });
    };

    window.addEventListener('click', onWindowClick, false);
    return () => window.removeEventListener('click', onWindowClick, false);
  }, []);

  return (
    <div className='dropdowns'>
      {preFiltered === false ?
      <Dropdown
        opened={mainTagsOpened}
        onClick={e => {
          e.stopPropagation();
          setState({ mainTagsOpened: !mainTagsOpened, tagsOpened: false });
        }}
        onOptionChange={onMainTagsOptionChange}
        type='dark'
        options={mainTags.map(x => ({
          option: x.name,
          checked: !filters.tagGroup && Boolean(filters.mainTags.includes(x.name)),
          additionalText: ` (${filters.coursesCountForTags[x.name] || 0}) `
        }))}
      >
        Ämnesområde
      </Dropdown>
      : ""}
      <Dropdown
        opened={tagsOpened}
        onClick={e => {
          e.stopPropagation();
          if (filters.mainTags.length > 0) {
            setState({ tagsOpened: !tagsOpened, mainTagsOpened: false });
          }
        }}
        disabled={filters.tagGroup || filters.mainTags.length <= 0}
        onOptionChange={onTagsOptionChange}
        type='white'
        options={filters.tags.sort((a,b) => String(a).localeCompare(String(b), 'sv', {
          sensitivity: 'base'
        })).map(x => ({
          option: x,
          checked: filters.activeTags.includes(x)
        }))}
      >
        Valda delämnen
      </Dropdown>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  filter: filtersToSplit => dispatch(applyFilters({ ...filtersToSplit, tagGroup: null }))
});

const mapStateToProps = state => {
  return {
    initialFilters: state.initialFilters,
    filters: state.filters
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TagsFilters);
