import React from 'react';
import Button from '../../../molecules/Button';
import { connect } from 'react-redux';
import { applyFilters } from '../actions';

const gtmOnClick = (title) => {
  window.dataLayer && window.dataLayer.push({
    'filterTitle': title,
    'event': 'coursesAndCertificationsQuickFilter'
  });
}

const TagGroupsFilters = ({ initialFilters: { tagGroups, mainTags }, filters: { tagGroup }, filter }) => (
  <div className='tag-groups-filters'>
    <h3 className='tag-groups-filters__label container'>Snabbfilter</h3>
    <div className='tag-groups-filters__wrapper container'>
      {tagGroups.map(({ name, iconUrl, tags, active }, index) => (
        <Button
          key={name}
          iconFromUrl
          active={name === tagGroup}
          iconUrl={iconUrl}
          onClick={() => {
            gtmOnClick(name);
            const activeTags = !tags ? [] : tags.filter(x => !mainTags.find(t => t.name === x.name)).map(x => x.name);
            const activeMainTags = !tags ? [] : tags.filter(x => !activeTags.includes(x.name)).map(x => x.name);
            filter({
              activeTags: activeMainTags.concat(...activeTags),
              tagGroup: name
            });
          }}
          type='tertiary'
          inverted
          additionalClass={`cell-${index + 1}`}
        >
          {name}
        </Button>
      ))}
    </div>
  </div>
);

const mapDispatchToProps = dispatch => ({
  filter: filtersToSplit => dispatch(applyFilters(filtersToSplit))
});

const mapStateToProps = state => {
  return {
    initialFilters: state.initialFilters,
    filters: state.filters,
    tagGroups: state.initialFilters.tagGroups
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TagGroupsFilters);
