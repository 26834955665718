import React from 'react';
import Icon from '../atoms/Icon';

const Loader = ({ children, loading }) => {
  if (loading) {
    return (
      <div className="loader">
        <Icon type="circle" rotate />
      </div>
    );
  }
  return <>{children}</>;
};

export default Loader;
