import React from 'react';
import { connect } from 'react-redux';
import { applyFilters } from '../actions';
import TagLabel from '../../../atoms/TagLabel';

const TagLabelsFilters = ({ filters: { mainTags, activeTags, tagGroup }, filter, initialFilters: { preFiltered }}) => {
  const onManipulateWhenTagGroupActive = () => {
    filter({
      mainTags: [],
      activeTags: []
    })
  }
  const onMainTagRemove = name => {
    if(tagGroup) {
      onManipulateWhenTagGroupActive();
      return;
    }
    const newMainTags = mainTags.filter(x => x !== name);
    filter({ mainTags: newMainTags });
  };
  const onTagRemove = name => {
    if(tagGroup) {
      onManipulateWhenTagGroupActive();
      return;
    }
    const newActiveTags = activeTags.filter(x => x !== name);
    filter({ activeTags: newActiveTags });
  };

  if (preFiltered === false) {
    return <div className='tag-labels-filters container'>
      <>
      {mainTags.map(x => <TagLabel onXClick={() => onMainTagRemove(x)}>{x}</TagLabel>)
              .concat(...activeTags.map(x => <TagLabel onXClick={() => onTagRemove(x)}>{x}</TagLabel>))}
      </>
    </div>
  } else {
    return <div className='tag-labels-filters container'>
      <>
      {activeTags.map(x => <TagLabel onXClick={() => onTagRemove(x)}>{x}</TagLabel>)}
      </>
    </div>
  }

}

const mapDispatchToProps = dispatch => ({
  filter: filtersToSplit => dispatch(applyFilters({ ...filtersToSplit, tagGroup: null }))
});

const mapStateToProps = state => {
  return {
    filters: state.filters,
    initialFilters: state.initialFilters
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TagLabelsFilters);
