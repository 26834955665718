import Icon from '../atoms/Icon';
import React from 'react';

const Button = ({ onClick, type, icon, iconFromUrl, active, iconUrl, size, inverted, children, warning, disabled, additionalClass }) => {
  let className = `button`;
  className += ` button--size-${size || 'normal'}`;
  let props = {};

  if(disabled) {
      props.disabled = 'disabled';
  }

  if (type == 'secondary' && inverted) {
    className += ` button--secondary-inverted`;
  } 
  else if(type == 'secondary' && warning) {
    className += ` button--secondary-warning`;
  } 
  else {
    className += ` button--${type}`;
  }

  if(additionalClass) {
    className += ` ${additionalClass}`;
  }

  if(active) {
    className += ' button--active';
  }

  return (
    <button {...props} className={className} onClick={onClick} type={type} size={size}>
      {icon && !iconFromUrl && <Icon type={icon} />}
      {iconFromUrl && <Icon size={size} fromUrl url={iconUrl}/> }
      {children}
    </button>
  );
};

export default Button;
