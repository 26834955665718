import React, { useState } from 'react';
import sessionsService from '../services/sessionsService';

export default Component => (props) => {
  const [favorites, setFavorites] = useState(sessionsService.getFavorites());
  const addToFavorites = sessionId => setFavorites(sessionsService.addToFavorites(sessionId));
  const removeFromFavorites = sessionId => setFavorites(sessionsService.removeFromFavorites(sessionId));
  return (
    <>
      <Component
        {...props}
        favorites={favorites}
        addToFavorites={addToFavorites}
        removeFromFavorites={removeFromFavorites}
      />
    </>
  );
};
