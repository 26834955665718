import React, { useState, useEffect } from 'react';

const Counter = ({ secondsToEvent, translations }) => {
    const [seconds, setState] = useState(secondsToEvent);

    useEffect(() => {
        const interval = setInterval(() => {
            setState((prevSeconds) => prevSeconds - 1);
        }, 1000);
        return () => clearInterval(interval);
    }, []);
    
    const days             = Math.floor(seconds/24/60/60),
          hoursLeft        = Math.floor((seconds) - (days*86400)),
          hours            = Math.floor(hoursLeft/3600),
          minutesLeft      = Math.floor((hoursLeft) - (hours*3600)),
          minutes          = Math.floor(minutesLeft/60),
          remainingSeconds = seconds % 60;
    return <ul className='counter__countdown'>
        <li className='pair'>
            <span className='pair__name'>{days === 1 ? translations['day'] : translations['days']}</span>
            <span className='pair__value'>{days}</span>
        </li>
        <li className="pair">
            <span className="pair__name">{hours === 1 ? translations['hour'] : translations['hours']}</span>
            <span className="pair__value">{hours}</span>
        </li>
        <li className="pair">
            <span className="pair__name">{translations['minutes']}</span>
            <span className="pair__value">{minutes}</span>
        </li>
        <li className="pair">
            <span className="pair__name">{translations['seconds']}</span>
            <span className="pair__value">{Number.parseInt(remainingSeconds)}</span>
        </li>
    </ul>
}

export default Counter;