import React, { useEffect } from 'react';
import Checkbox from '../../../molecules/Checkbox';
import moment from 'moment';
import { connect } from 'react-redux';
import { applyFilters, applySearchParameters } from '../actions';

const gtmOnClick = filterNameWithValue => {
  window.dataLayer &&
    window.dataLayer.push({
      filterTitle: filterNameWithValue,
      event: 'coursesAndCertificationsAdvancedFilter'
    });
};

const createDates = followingMonthsNumber => {
  const today = new Date();
  const dates = Array.from({ length: followingMonthsNumber }, (_, index) => {
    const date = new Date(today.getFullYear(), today.getMonth() + index, today.getDay());
    return {
      month: date.getMonth() + 1,
      year: date.getFullYear()
    };
  });

  return dates;
};

const compareNumbers = (a, b) => {
  if (a > b) {
    return 1;
  }

  if (a < b) {
    return -1;
  }

  return 0;
};

const AdvancedFilters = ({
  filter,
  search,
  initialFilters,
  filters,
  isInitialized,
  filterTypeTooltips,
  afterInitialized,
  eLearningToolTip,
  applySearchParameters
}) => {
  const initialDates = createDates(initialFilters.followingMonthsNumber);
  const initialCities = initialFilters.cities;
  const { cities, dates, availableCities, availableDates, masterCourse, startGuarantee, vendor, voucher } = filters;
  const { includeELearning, onlyELearning } = search;

  const onCityChange = (city, checked) => {
    let { cities } = filters;
    if (checked) {
      gtmOnClick(`Ort: ${city}`);
      cities.push(city);
    } else {
      cities = cities.filter(c => c !== city);
    }
    filter({ cities });
  };
  const onDateChange = (date, checked) => {
    let { dates } = filters;
    if (checked) {
      gtmOnClick(`Kursstart: ${moment(`${date.month} ${date.year}`, 'MM YYYY').format('MMMM YYYY')}`);
      dates.push(date);
    } else {
      dates = dates.filter(d => !(d.month == date.month && d.year == date.year));
    }
    filter({ dates });
  };

  useEffect(() => {
    if (
      isInitialized &&
      (cities.length > 0 || dates.length > 0 || masterCourse || startGuarantee || vendor || voucher || onlyELearning || includeELearning)
    ) {
      afterInitialized(true);
    }
  }, [isInitialized]);

  return (
    <div className={`advanced-filters advanced-filters--hidden`} id='advanced-filters'>
      <div className='container advanced-filters__wrapper'>
        <div className='filters-group filters-group--cities'>
          <label className='filters-group__label'>Ort</label>
          {initialCities.map(city => (
            <Checkbox
              onChange={e => onCityChange(city, e.target.checked)}
              checked={cities.find(x => x === city)}
              key={`${city}`}
              label={city}
            />
          ))}
        </div>
        <div className='filters-group filters-group--course-start'>
          <label className='filters-group__label'>Kursstart</label>
          {initialDates
            .sort((a, b) => compareNumbers(a.year, b.year) || compareNumbers(a.month, b.month))
            .map(d => (
              <Checkbox
                onChange={e => onDateChange(d, e.target.checked)}
                checked={dates.find(x => x.month === d.month && x.year === d.year)}
                key={`${d.month + d.year}`}
                label={moment(`${d.month} ${d.year}`, 'MM YYYY').format('MMMM YYYY')}
              />
            ))}
        </div>
        <div className='filters-group filters-group--filters'>
          <label className='filters-group__label'>Övrigt</label>
          <Checkbox
            onChange={e => {
              e.target.checked && gtmOnClick('Övrigt: Startgaranti');
              filter({ startGuarantee: e.target.checked });
            }}
            checked={startGuarantee}
            defaultChecked={startGuarantee}
            label='Startgaranti'
            tooltipName='startGuaranteeTooltip'
            tooltipContent={filterTypeTooltips.startGuaranteeTooltip}
          />
          <Checkbox
            onChange={e => {
              e.target.checked && gtmOnClick('Övrigt: Masterclass');
              filter({ masterCourse: e.target.checked });
            }}
            checked={masterCourse}
            defaultChecked={masterCourse}
            label='Masterclass'
            tooltipName='masterCourseTooltip'
            tooltipContent={filterTypeTooltips.masterCourseTooltip}
          />
          <Checkbox
            onChange={e => {
              e.target.checked && gtmOnClick('Övrigt: Leverantörsvoucher');
              filter({ vendor: e.target.checked });
            }}
            checked={vendor}
            defaultChecked={vendor}
            label='Leverantörsvoucher'
            tooltipName='vendorVoucherTooltip'
            tooltipContent={filterTypeTooltips.vendorVoucherTooltip}
          />
          <Checkbox
            onChange={e => {
              e.target.checked && gtmOnClick('Övrigt: Kompetenskort');
              filter({ voucher: e.target.checked });
            }}
            checked={voucher}
            defaultChecked={voucher}
            label='Kompetenskort'
            tooltipName='voucherTooltip'
            tooltipContent={filterTypeTooltips.voucherTooltip}
          />
          <Checkbox
            onChange={e => {
              e.target.checked && gtmOnClick('Övrigt: E-learning');
              applySearchParameters({ includeELearning: e.target.checked }, true);
            }}
            checked={includeELearning}
            defaultChecked={includeELearning}
            label='Visa även E-learning'
            tooltipName='elearningtooltip'
            tooltipContent={eLearningToolTip}
          />
          <Checkbox
            onChange={e => {
              e.target.checked && gtmOnClick('Övrigt: Endast E-learning');
              applySearchParameters({ onlyELearning: e.target.checked }, true);
            }}
            checked={onlyELearning}
            defaultChecked={onlyELearning}
            label='Visa endast E-learning'
            tooltipName='onlyelearning'
            tooltipContent={eLearningToolTip}
          />
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  filter: filtersToSplit => dispatch(applyFilters({ ...filtersToSplit }, false)),
  applySearchParameters: (params, fetchAfter) => dispatch(applySearchParameters(params, fetchAfter))
});

const mapStateToProps = state => {
  return {
    initialFilters: state.initialFilters,
    filters: state.filters,
    search: state.search,
    isInitialized: state.isInitialized,
    filterTypeTooltips: state.filterTypeTooltips,
    eLearningToolTip: state.eLearningToolTipText
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedFilters);
