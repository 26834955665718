import { createContext, useState, useEffect } from 'react';
import Snackbar from '../components/molecules/Snackbar';

export const SnackBarContext = createContext()

const AUTO_DISMISS = 5000;

export function SnackBarProvider({ children }) {
  const [alert, setAlert] = useState(undefined);
  const [alertTimeOut, setAlertTimeout] = useState(undefined);
  
  useEffect(() => {
    if (alert) {
      if(alertTimeOut) {
          clearTimeout(alertTimeOut);
      }
      const timeout = setTimeout(() => {
          setAlert(undefined);
      }, AUTO_DISMISS);
      setAlertTimeout(timeout);
    }
  }, [alert])

  const showAlert = (alert) => setAlert(alert);

  const value = { showAlert }
    
  return (
    <SnackBarContext.Provider value={value}>
      {children}
      {alert && <Snackbar>{alert}</Snackbar>}
    </SnackBarContext.Provider>
  )
}