import React from 'react';
import GroupedSessionList from '../../molecules/GroupedSessionList';
import dateService from '../../../services/dateService';
import withProvidingLocalization from '../../../hoc/withProvidingLocalization';
import withFavorites from '../../../hoc/withFavorites';

const MyFavorites = ({ sessions, localization, favorites }) => {
    dateService.useLocalization(localization);
    return (<>
        {Object.entries(sessions
            .sort((a, b) => dateService.sortDateFunc(dateService.parseDate(a.from), dateService.parseDate(b.from)))
            .reduce((groups, session) => {
                const from = dateService.parseDate(session.from);
                const key = dateService.formatDate(from).toUpperCase();
                if (groups[key]) {
                    groups[key].push(session);
                } else {
                    groups[key] = [session];
                }
                return groups;
            }, {})).map(([date, sessions], i) => (<React.Fragment key={date}>
                {sessions.filter(x => favorites.includes(x.id)).length > 0 && <h2 className='heading'>{date}</h2>}
                <GroupedSessionList sessions={sessions} favorites={favorites} showOnlyFavorites>
                    {(sessions, mapSession) => (<>
                        {sessions.map(mapSession)}
                    </>)}
                </GroupedSessionList>
            </React.Fragment>))}
    </>);
};

export default withFavorites(withProvidingLocalization(MyFavorites));
