import React from 'react';

const ToolTip = ({ children, name }) => {
    const onClick = (e) => {
        window.toggleTooltip(String(name), 'tooltip-text--hidden');
        e.stopPropagation();
        e.preventDefault();
    };
    return <>
        <span className='tooltip-icon tooltip-icon-container' onClick={onClick}>
            <i className='ic-question-circle' id={`icon-${name}`}></i>
        </span>
        <div className={`tooltip-text tooltip-text--hidden box-${name}`}>
            {children}
        </div>
    </>;
};

export default ToolTip;