import React from 'react';
const Separator = ({ type, dotted }) => {
  let className = 'separator';
  if (type) {
    className += ` separator--${type}`;
  }
  if (dotted) {
    className += ' separator--dotted';
  }
  return <div className={className} />;
};

export default Separator;
