import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';
import { extendInitialState } from './initialState';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default initialProperties => {
  extendInitialState(initialProperties);
  return createStore(rootReducer, composeEnhancers(
      applyMiddleware(thunk)
    ));
};
