import React, { useEffect, useState } from 'react';
import CourseCard from '../../molecules/CourseCard';
import { connect } from 'react-redux';
import { initialize, nextPage as nextPageRequest, previousPage as previousPageRequest } from './actions';
import Filters from './Filters';
import Loader from '../../molecules/Loader';
import Search from './Search';
import Sorter from './Sorter';

const CourseSearch = ({
  initialize,
  previousPage,
  nextPage,
  phrase,
  pagination: { currentPage, canPreviousPage, canNextPage, totalPages, totalItems },
  courses,
  isFetching
}) => {
  const [phraseLabel, setPhraseLabel] = useState(null);

  const nextPageClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    nextPage();
  }

  const prevPageClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    previousPage();
  }

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    if (!isFetching && phrase) {
      setPhraseLabel(phrase);
    } else if (!phrase) {
      setPhraseLabel(null);
    }
  }, [isFetching]);

  return (
    <>
      <Search />
      <Filters />
      <div className='container flex-container--center'>
        <div className='courses-length'>
          {phraseLabel && (
            <>
              {`Sökresultat för `}
              <span className='search-phrase'>{phraseLabel}</span>
              {' / '}
            </>
          )}
          {totalItems} utbildningar visas
        </div>
        <Sorter />
      </div>
      <Loader loading={isFetching}>
        <div className='container'>
          <div className='courses'>
            {courses.map(x => (
              <CourseCard key={x.key} course={x} onClick={() => (window.location.href = x.absoluteUrl)} />
            ))}
            {totalPages > 1 && (
              <div className='pagination'>
                <div className='container'>
                  <div
                    className={`pagination__previous${!canPreviousPage ? ' disabled' : ''}`}
                    onClick={canPreviousPage ? prevPageClick : undefined}
                  >
                    <i className='ic-chevron-left' />
                  </div>
                  <div className='pagination__info'>
                    {currentPage}&nbsp;av&nbsp;{totalPages}
                  </div>
                  <div
                    className={`pagination__next${!canNextPage ? ' disabled' : ''}`}
                    onClick={canNextPage ? nextPageClick : undefined}
                  >
                    <i className='ic-chevron-right' />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Loader>
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  initialize: () => dispatch(initialize()),
  nextPage: () => dispatch(nextPageRequest()),
  previousPage: () => dispatch(previousPageRequest())
});

const mapStateToProps = state => {
  const page = state.pagination.currentPage - 1;
  const paginatedCourses = state.filteredCourses.slice(
    state.pagination.take * page,
    (page + 1) * state.pagination.take
  );
  const totalPages = Math.ceil(state.filteredCourses.length / state.pagination.take);
  const totalItems = state.filteredCourses.length;
  const canNextPage = state.pagination.currentPage < totalPages;
  const canPreviousPage = state.pagination.currentPage > 1;
  return {
    courses: paginatedCourses,
    isFetching: state.isFetching,
    eLearningToolTip: state.eLearningToolTipText,
    filterTypeTooltips: state.filterTypeTooltips,
    phrase: state.search.phrase,
    pagination: {
      ...state.pagination,
      canNextPage,
      canPreviousPage,
      totalPages,
      totalItems
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CourseSearch);
