const ITEMS_PER_PAGE = 20;

const initialState = {
  courses: [],
  filteredCourses: [],
  isFetching: false,
  isInitialized: false,
  eLearningToolTipText: null,
  initialFilters: {
    mainTags: [],
    cities: [],
    followingMonthsNumber: 8,
    preFiltered: false
  },
  search: {
    phrase: null,
    searchInName: false,
    includeELearning: false,
    onlyELearning: false
  },
  filters: {
    mainTags: [],
    tags: [],
    activeTags: [],
    availableCities: [],
    availableDates: [],
    coursesCountForTags: {},
    cities: [],
    dates: [],
    startGuarantee: false,
    masterCourse: false,
    vendor: false,
    voucher: false
  },
  sorting: {
    sortby: 'relevance',
    direction: 'asc',
    highlightedFirst: true,
    fromUrl: false
  },
  pagination: {
    take: ITEMS_PER_PAGE,
    currentPage: 1
  }
};

const extendInitialState = additionalProperties => {
  if (!additionalProperties) {
    return;
  }
  for (let property in additionalProperties) {
    if (initialState[property]) {
      initialState[property] = { ...initialState[property], ...additionalProperties[property] };
    } else {
      initialState[property] = additionalProperties[property];
    }
  }
};

export { initialState, extendInitialState };
